@media (min-width: 800px) {
    .card{
        margin: 0 30% 2% !important;
    }
    .container{
        padding: 5% !important;
    }
}

@media (max-width: 799px) {
    .card{
        margin: 0 5% 2% !important;
    }   
    .container{
        padding: 5% !important;
    }
}