:root {
  --rouge-crit: #D50032;
  --gris-crit: #333F48;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F1F1F1;
  color: #25333C;
}

[class*=amplify]{
  font-family: var(--text-font) !important;
}

.amplify-heading{
  font-family: var(--title-font) !important;
  color: var(--rouge-crit);
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: var(--amplify-components-card-padding);
}

.amplify-heading--1{
  font-size: 23px;
}

.amplify-button--link{
  padding: 0 !important;
  margin-bottom: 24px;
  color: var(--gris-crit) !important;
  font-weight: normal;
}

.amplify-button--link:focus{
  box-shadow: none !important;
}

.amplify-field__error-message{
  font-size: 10px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.critCaption{
  color: var(--rouge-crit);
}

.crit-button{
  font-family: var(--title-font) !important;
  margin-top: 40px;
  text-transform: uppercase !important;
  font-size: 16px !important;
}

.crit-button > button{
  min-width: 200px;
}

.requiredField > label:before, .requiredField > .amplify-field > label:before, .amplify-text.requiredField:before{
  content: "* ";
  color: var(--rouge-crit);
}

.multipleChoices > label:after, .multipleChoices > .amplify-field > label:after, .amplify-text.multipleChoices:after{
  content: "\APlusieurs choix possibles";
  font-style: italic;
  font-size: small;
  font-weight: normal;
  white-space: pre-wrap;
}

.amplify-label{
  font-weight: bold;
}

.amplify-radiogroupfield .amplify-radiogroup{
  width: 100%;
}

input[type="date" i] {
  align-items: center;
}

input[type="date"] {
  min-height: 50px !important;
}

.amplify-checkbox__icon--checked {
  color: #FFFFFF;
}

.amplify-radio__button {
  min-width: var(--amplify-components-radio-button-width);
  min-height: var(--amplify-components-radio-button-height);
}

.justify {
  text-align: justify;
  text-justify: inter-word;
}

/* unvisited link */
a:link {
  color: #ff6a14;
}

/* visited link */
a:visited {
  color: #ff6a14;
}

/* mouse over link */
a:hover {
  color: #ff6a14;
}

/* selected link */
a:active {
  color: #ff6a14;
}